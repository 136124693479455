<template>
  <div class="box">
    <div v-if="!isApp" class="tabbar" :class="navBarFixed ? 'navBarFixed' : ''">
      <img @click="goback" src="../../../../static/common/logo.png" alt="" />
    </div>
    <img class="banner" src="/subject/report/wap/banner.png" alt="">
    <div class="content">
      <div class="part part_1">
        <img style="margin-top:-10px;" src="/subject/report/wap/title1.png" alt="">
        <div class="videoFrame">
          <video class="craig" v-if="!ckplayerObject.live" :src="isVideo" controls="controls" autoplay="autoplay" muted></video>
          <video v-if="ckplayerObject.live" id="videoPlayer" class="video-js" muted></video>
        </div>
        <img style="margin-top:-70px;" src="/subject/report/wap/1_banner.png" alt="">
        <p class="buttonBox">
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_2">
        <img style="margin-top:-10px" src="/subject/report/wap/title2.png" alt="">
        <div>
          <img src="/subject/report/wap/teacher1.png" alt="">
          <img src="/subject/report/wap/teacher2.png" alt="">
          <img src="/subject/report/wap/teacher3.png" alt="">
        </div>
        <img src="/subject/report/wap/2_banner.png" alt="">
        <p class="buttonBox">
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_3">
        <img style="margin-top:-5px; width:75.5%;" src="/subject/report/wap/title3.png" alt="">
        <img style="width:100%;margin-top:-12px;" src="/subject/report/wap/timeTable.png" alt="">
        <p class="buttonBox" style="margin-top:-5px;">
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_4">
        <img class="book" src="/subject/report/book.png" alt="">
        <img style="margin-top:-5px; width:85%;" src="/subject/report/wap/title4.png" alt="">
        <img style="margin:-100px 0 -95px; width:100%;" src="/subject/report/wap/stage.png" alt="">
        <img style="width:100%" src="/subject/report/wap/3_banner.png" alt="">
        <p class="buttonBox">
          <img @click="openLive" src="/subject/report/live800.png" alt="">
          <img @click="openLogin" src="/subject/report/loginImg.png" alt="">
        </p>
      </div>
      <div class="part part_5">
        <img style="margin-top:-5px; width:85%;" src="/subject/report/wap/title5.png" alt="">
        <img style="width:72%;" src="/subject/report/wap/media.png" alt="">
      </div>
    </div>
    <contactus v-if="!isApp"></contactus>
  </div>
</template>

<script>
import Contactus from '../../Contactus.vue';
import {livingRoomNow} from '../../../../api/info'
import videojs from 'video.js';
export default {
  name:'reportWap',
  components:{
    Contactus
  },
  data(){
    return{
      screenWidth:null,
      navBarFixed: false,
      scroll: Number,
      isApp: false,
      isVideo:'https://www.rlcvipltd.net/static/video/v-0320.mp4',
      timer:null,
      myPlyer:null,
      options:null,
    }
  },
  created(){
    // 传给App.vue,不展示tabbar
    this.$emit('controlShow',true);
    // App打开隐藏顶部和底部导航
    if(this.$route.query.isApp){
      this.isApp = true;
    };
    this.init();
    this.timer = window.setInterval(() => {
      setTimeout(this.init(), 0)
    }, 1000*10)
  },
  beforeDestroy() {
    //在 beforeDestroy生命周期函数中销毁定时器
    clearInterval(this.timer);
    this.timer = null;
  },
  computed:{
    ckplayerObject() {
      return this.$store.state.home.ckplayerObject
    },
  },
  methods:{
    // 获取是否直播以及直播流
    init(){
      livingRoomNow({platid:1}).then(res=>{
        if(res.Status == 0 && res.content.length>0){
          this.$store.commit('home/set_ckplayerObject',{video: res.content[0].param.pcurl ,live: true})
          this.isVideo = res.content[0].param.pcurl;
          setTimeout(()=>{
            this.initVideo(this.isVideo);
          },500)
        }else{
          this.$store.commit('home/set_ckplayerObject',{video: 'https://www.rlcvipltd.net/static/video/v-0320.mp4' , live: false})
          this.isVideo = 'https://www.rlcvipltd.net/static/video/v-0320.mp4'; 
          // this.myPlyer.dispose();
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 返回上级页面
    goback() {
      window.location.href = window.location.origin;
    },
    // 控制顶部导航栏的定位
    handleScroll() {
      this.scroll = document.documentElement.scrollTop + document.body.scrollTop; //获取滚动后的高度
      if(this.scroll > 90 ){
        this.navBarFixed = true;
      }else{
        this.navBarFixed = false;
      }
    },
    openLogin() {
      window.open("https://www.rlcvipltd.net/uc/login");
    },
    openLive() {
      window.open(
        this.$parent.live800Url
      );
    },
    // 初始化videojs内容
    initVideo(nowPlayVideoUrl) {
      // 这些options属性也可直接设置在video标签上，见 muted
      this.options = {
        autoplay: true, // 设置自动播放
        controls: true, // 显示播放的控件
        sources: [
        // 注意，如果是以option方式设置的src,是不能实现 换台的 (即使监听了nowPlayVideoUrl也没实现)
          {
          src: nowPlayVideoUrl,
          type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
          }
        ]
      };
      // videojs的第一个参数表示的是，文档中video的id
      this.myPlyer = videojs("videoPlayer", this.options, function onPlayerReady() {
      });
    },
  },
  mounted(){
    window.addEventListener('scroll', this.handleScroll);
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })()
    };
  },
  watch: {
    screenWidth: function (n) {
      if (n > 500) {
        this.$router.push({
          name: "reportPC",
        });
      }
    },
  },
}
</script>

<style lang="less" scoped>
  .box{
    width: 100%;
    max-width: 450px;
    .tabbar {
      width: 100%;
      background-color: #ffffff;
      img {
        margin: 10px 30px;
        width: 130px;
      }
    }
    .navBarFixed {
      position: fixed;
      top: 0;
      z-index: 999;
    }
    .banner{
      width: 100%;
      display: block;
    }
    .content{
      width: 100%;
      height: 3230px;
      background: url('/subject/report/wap/background.png') no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      .part{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .buttonBox{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -20px;
          img{
            width: 130px;
            margin: 0 10px;
          }
        }
      }
      .part_1{
        img{
          width: 82%;
        }
        .videoFrame{
          width: 330px;
          height: 310px;
          background: url('/subject/report/wap/videoFrame.png') no-repeat;
          background-size: 100% 100%;
          margin-top: -25px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          .craig{
            width: 310px;
            margin-top: 10px;
          }
        }
      }
      .part_2{
        img{
          width: 375px;
        }
        div{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: -25px;
          img{
            width: 92%;
            margin-bottom: 13px;
          }
        }
      }
      .part_4{
        position: relative;
        .book{
          width: 150px;
          position: absolute;
          top: 47.5%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }
  }
  @media screen and (min-width: 380px) {
    .box{
      .content{
        height: 3500px;
        .part_4{
          .book{
            width: 170px;
            position: absolute;
            top: 47.5%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
    }
  }
  /deep/ .videoPlayer-dimensions{
    width: 310px;
    height: 180px;
    margin-top: 10px;
  }
</style>